import React from "react";
import { eventLog } from "../utils/function";

function Header({ randKey, ui, setMainError }) {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{ ...ui.header.style, width: "100%", left: "0" }}
      >
        <span className="navbar-brand">
          <img
            src={ui.header.vendorLogo}
            className="headerlogo"
            alt="Vendor Logo"
          />
        </span>
        <ul className="ml-auto my-auto headernavbar-nav">
          <span
            className="d-none d-md-block welcome-text"
            style={{
              marginRight: "50px",
              fontSize: "18px",
              color: `${ui.header.style.color}`,
              fontWeight: "500",
            }}
          >
            Welcome {ui.custname}
          </span>
          {ui.header.payNowBtn && ui.header.payNowBtn.value && (
            <button
              className="headerbtn btn btn-primary mr-1 d-xl-none"
              style={ui.header.payNowBtn.style}
              title={ui.header.payNowBtn.tooltip}
              onClick={() => {
                window.open(ui.link ? ui.link : "#");
                const data = [
                  {
                    RAND_KEY: randKey,
                    CUST_ID: ui.cust_id,
                    CAMP_ID: ui.camp_id,
                    STATUS: "Pay Now Clicked.",
                  },
                ];
                eventLog(data);
              }}
            >
              {ui.header.payNowBtn.value}
            </button>
          )}
          <button
            className="headerbtn btn btn-primary headerexit-btn"
            style={ui.header.exitBtn.style}
            title={ui.header.exitBtn.tooltip}
            onClick={() => {
              let confirm = window.confirm(ui.header.exitBtn.tooltip);
              if (confirm) {
                const data = [
                  {
                    RAND_KEY: randKey,
                    CUST_ID: ui?.cust_id,
                    CAMP_ID: ui?.camp_id,
                    STATUS: "Log Out Successfully.",
                  },
                ];

                eventLog(data);
                localStorage.removeItem("user");
                setMainError(
                  "Thank you for visiting our site. You have successfully logged out."
                );
              }
            }}
          >
            <i className="fa fa-sign-out"></i>
            <span className="headerexit-text d-none d-sm-inline">
              {ui.header.exitBtn.value}
            </span>
          </button>
        </ul>
      </nav>
    </>
  );
}

export default Header;
