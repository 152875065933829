import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import config from "../config";
import { eventLog, fetchCompUi, errLog } from "../utils/function";

function Call({ randKey, setError }) {
  const [ui, setUi] = useState({});
  // const [mobileView, setMobileView] = useState(
  //   window.screen.width < 481 ? true : false
  // );
  const [mobileView, setMobileView] = useState(window.innerWidth < 481);
  const [date, setDate] = useState({ err: false, value: "" });
  const [formatedDate, setFormatedDate] = useState("");
  const [time, setTime] = useState({ err: false, value: "" });
  const [submit, setSubmit] = useState({ loading: "", message: "" });
  const [btnMsg, setBtnMsg] = useState("");
  const [scheduleShow, setScheduleShow] = useState(true);
  const [cancleError, setCancleError] = useState("");
  const [callPopup, setcallPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));
  const months = [
    "DEF",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  useEffect(() => {
    fetchCompUi(5, setData, randKey, token, obj);
  }, []);

  useLayoutEffect(() => {
    function updateLayout() {
      const isMobile = window.innerWidth < 481;
      setMobileView(isMobile);
    }

    function handleClickOutside(event) {
      const elem = document.querySelector("#call");
      if (mobileView && elem && !elem.contains(event.target)) {
        setcallPopup(false);
        setIsOpen(false); // Close the div only in mobile view\
      }
    }

    window.addEventListener("resize", updateLayout);
    if (mobileView) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("resize", updateLayout);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileView]);

  // useLayoutEffect(() => {
  //   function updateLauyot() {
  //     const elem = document.querySelector("#call");
  //     if (elem.style.left) {
  //       if (elem.style.removeProperty) {
  //         elem.style.removeProperty("left");
  //       } else {
  //         elem.style.removeAttribute("left");
  //       }
  //     }
  //     setMobileView(window.screen.width < 481 ? true : false);
  //   }
  //   window.addEventListener("resize", updateLauyot);

  //   return () => {
  //     window.removeEventListener("resize", updateLauyot);
  //   };
  // }, []);
  const obj = {
    msg: "Dialer Component Loaded.",
    onError: (msg) => {
      setError(msg);
    },
  };
  const setData = (data) => {
    setUi(data);
  };
  function popup() {
    document.getElementById("tb-cont").style.left = !callPopup ? "4%" : "102%";
    setcallPopup(!callPopup);
    setIsOpen(!isOpen);
  }
  const payNow = (url) => {
    window.open(url);
    const data = [
      {
        RAND_KEY: randKey,
        CUST_ID: ui.cust_id,
        CAMP_ID: ui.camp_id,
        STATUS: "Pay Now Clicked.",
      },
    ];
    eventLog(data);
  };
  const callMeNow = (msg) => {
    axios
      .post(
        `${config.API_URL}/ms/datatocall/`,
        {
          randkey: randKey,
          custid: ui.cust_id,
          campid: ui.camp_id,
          status: "CALL_MEW_NOW_CLICKED",
          today_click: 0,
          listid: ui.listId,
          phone1: ui.mobile,
          huntgroup: ui.huntgroup,
          dialerserver: ui.dialerIp,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setBtnMsg(msg);
        const data = [
          {
            RAND_KEY: randKey,
            CUST_ID: ui.cust_id,
            CAMP_ID: ui.camp_id,
            STATUS: "Call Me Now Button Clicked.",
          },
        ];
        eventLog(data);
      })
      .catch((err) => {
        setBtnMsg(err.message);
        const data = {
          sNo: "9999",
          taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/datatocall/`,
          errMessage: {
            Message: err.message,
          },
          errRemarks: "ERROR OCCURED WHILE CALL ME NOW BTN CLICKING.",
        };
        errLog(data);
      });
  };
  const scheduleSubmit = () => {
    if (!date.value) setDate({ ...date, err: true });
    if (!time.value) setTime({ ...time, err: true });
    if (time.value && date.value) {
      if (
        ui.dialer.isSundayWorking === "false" &&
        new Date(date.value).getUTCDay() === 0
      ) {
        setSubmit({
          message:
            "Please schedule between Monday to Saturday only. Sunday is not our working day.",
          loading: false,
        });
        return false;
      }
      if (
        ui.dialer.timeStart &&
        new Date("10/10/1995 " + time.value) <
          new Date("10/10/1995 " + ui.dialer.timeStart)
      ) {
        setSubmit({
          message: "We are available after " + ui.dialer.timeStart,
          loading: false,
        });
        return false;
      }
      if (
        ui.dialer.timeEnd &&
        new Date("10/10/1995 " + time.value) >
          new Date("10/10/1995 " + ui.dialer.timeEnd)
      ) {
        setSubmit({
          message: "We are available before " + ui.dialer.timeEnd,
          loading: false,
        });
        return false;
      }
      setSubmit({ message: "", loading: true });
      axios
        .post(
          `${config.API_URL}/ms/schedulecall/`,
          {
            randkey: randKey,
            custid: ui.cust_id,
            campid: ui.camp_id,
            status: "SCHEDULE-CALL-CLICK",
            today_click: 0,
            listid: ui.listId,
            phone1: ui.mobile,
            huntgroup: ui.huntgroup,
            dialerserver: ui.dialerIp,
            calldate: formatedDate,
            calltime: time.value,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          setSubmit({
            loading: false,
            message: `Thank you for requesting a call back.We will call you on requested time.`,
          });
          setScheduleShow(false);
          const data = [
            {
              RAND_KEY: randKey,
              CUST_ID: ui.cust_id,
              CAMP_ID: ui.camp_id,
              STATUS: "Scheduled Button Clicked.",
            },
          ];
          eventLog(data);
        })
        .catch((err) => {
          setSubmit({ loading: false, message: err.message });
          const data = {
            sNo: "9999",
            taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/schedulecall/`,
            errMessage: {
              Message: err.message,
            },
            errRemarks: "ERROR OCCURED WHILE SCHEDULE BTN CLICKING.",
          };
          errLog(data);
        });
    }
  };
  const cancleSchedule = () => {
    axios
      .post(
        `${config.API_URL}/ms/schedulecall/`,
        {
          randkey: randKey,
          custid: ui.cust_id,
          campid: ui.camp_id,
          status: "SCHEDULE-CALL-CANCEL",
          today_click: 0,
          listid: ui.listId,
          phone1: ui.mobile,
          huntgroup: ui.huntgroup,
          dialerserver: ui.dialerIp,
          calldate: formatedDate,
          calltime: time.value,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setScheduleShow(true);
        const data = [
          {
            RAND_KEY: randKey,
            CUST_ID: ui.cust_id,
            CAMP_ID: ui.camp_id,
            STATUS: "Scheduled Cancle Button Clicked.",
          },
        ];
        eventLog(data);
      })
      .catch((err) => {
        setCancleError(err.message);
        const data = {
          sNo: "9999",
          taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/schedulecall/`,
          errMessage: {
            Message: err.message,
          },
          errRemarks: "ERROR OCCURED WHILE SCHEDULE CANCLE BTN CLICKING.",
        };
        errLog(data);
      });
  };
  if (submit.message) {
    setTimeout(() => {
      setSubmit({ loading: false, message: "" });
    }, 2000);
  }
  const tabSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {ui.dialer && (
        <div>
          <div
            className="tab-content shadow-sm"
            id="tb-cont"
            style={{ zIndex: "999" }}
          >
            {isOpen && mobileView ? (
              <div
                className="card pb-0 tab-pane active"
                id="call"
                style={{ ...ui.dialer.upSection.style, zIndex: "9" }}
              >
                <div className="card-body text-center">
                  <h5 className="card-title">
                    {ui.dialer.upSection.content.label}
                  </h5>
                  <p className="card-text mb-3">
                    {ui.dialer.upSection.content.value}
                  </p>
                  <button
                    className="btn btn-success"
                    style={ui.dialer.upSection.action.style}
                    onClick={() => payNow(ui.link)}
                    title={ui.dialer.upSection.action.tooltip}
                  >
                    <i className="fa fa-money"></i>{" "}
                    {ui.dialer.upSection.action.value}
                  </button>
                </div>
                <div
                  className="jumbotron text-center"
                  style={{
                    ...ui.dialer.downSection.style,
                    marginBottom: "initial",
                  }}
                >
                  {!scheduleShow ? (
                    <>
                      <h5>
                        {cancleError
                          ? cancleError
                          : `You have scheduled a call for ${formatedDate} at ${time.value}`}
                      </h5>
                      <br />
                      <button
                        className="btn btn-primary mb-2 mt-2"
                        type="button"
                        style={ui.dialer.downSection.btn2.style}
                        title="Click here to cancle your Schedule"
                        onClick={cancleSchedule}
                      >
                        <i className="fa fa-clock-o"></i> Cancel Schedule
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary mb-2 mt-2"
                        type="button"
                        data-toggle="modal"
                        data-target="#callmenow"
                        style={ui.dialer.downSection.btn1.style}
                        title={ui.dialer.downSection.btn1.tooltip}
                        onClick={() =>
                          callMeNow(ui.dialer.downSection.btn1.popUp.content)
                        }
                      >
                        <i className="fa fa-phone"></i>{" "}
                        {ui.dialer.downSection.btn1.value}
                      </button>
                      <br></br>
                      <h5>OR</h5>
                      <button
                        className="btn btn-primary mb-2 mt-2"
                        type="button"
                        data-toggle="modal"
                        data-target="#callmelater"
                        style={ui.dialer.downSection.btn2.style}
                        title={ui.dialer.downSection.btn2.tooltip}
                      >
                        <i className="fa fa-clock-o"></i>{" "}
                        {ui.dialer.downSection.btn2.value}
                      </button>

                      <h5>{ui.dialer.downSection.helpText}</h5>
                    </>
                  )}
                </div>
              </div>
            ) : null}

            {!mobileView ? (
              <div
                className="card pb-0 tab-pane active"
                id="call"
                style={{ ...ui.dialer.upSection.style, zIndex: "9" }}
              >
                <div className="card-body text-center">
                  <h5 className="card-title">
                    {ui.dialer.upSection.content.label}
                  </h5>
                  <p className="card-text mb-3">
                    {ui.dialer.upSection.content.value}
                  </p>
                  <button
                    className="btn btn-success"
                    style={ui.dialer.upSection.action.style}
                    onClick={() => payNow(ui.link)}
                    title={ui.dialer.upSection.action.tooltip}
                  >
                    <i className="fa fa-money"></i>{" "}
                    {ui.dialer.upSection.action.value}
                  </button>
                </div>
                <div
                  className="jumbotron text-center"
                  style={{
                    ...ui.dialer.downSection.style,
                    marginBottom: "initial",
                  }}
                >
                  {!scheduleShow ? (
                    <>
                      <h5>
                        {cancleError
                          ? cancleError
                          : `You have scheduled a call for ${formatedDate} at ${time.value}`}
                      </h5>
                      <br />
                      <button
                        className="btn btn-primary mb-2 mt-2"
                        type="button"
                        style={ui.dialer.downSection.btn2.style}
                        title="Click here to cancle your Schedule"
                        onClick={cancleSchedule}
                      >
                        <i className="fa fa-clock-o"></i> Cancel Schedule
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary mb-2 mt-2"
                        type="button"
                        data-toggle="modal"
                        data-target="#callmenow"
                        style={ui.dialer.downSection.btn1.style}
                        title={ui.dialer.downSection.btn1.tooltip}
                        onClick={() =>
                          callMeNow(ui.dialer.downSection.btn1.popUp.content)
                        }
                      >
                        <i className="fa fa-phone"></i>{" "}
                        {ui.dialer.downSection.btn1.value}
                      </button>
                      <br></br>
                      <h5>OR</h5>
                      <button
                        className="btn btn-primary mb-2 mt-2"
                        type="button"
                        data-toggle="modal"
                        data-target="#callmelater"
                        style={ui.dialer.downSection.btn2.style}
                        title={ui.dialer.downSection.btn2.tooltip}
                      >
                        <i className="fa fa-clock-o"></i>{" "}
                        {ui.dialer.downSection.btn2.value}
                      </button>

                      <h5>{ui.dialer.downSection.helpText}</h5>
                    </>
                  )}
                </div>
              </div>
            ) : null}
            <div
              className="card pb-0 tab-pane fade"
              style={{
                ...ui.dialer.upSection.style,
                zIndex: "9",
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
              }}
              id="whatsappTab"
            >
              <div className="card-body text-center">
                <h5 className="card-title">
                  {ui.dialer.upSection.content.label}
                </h5>
                <p className="card-text mb-3">
                  {ui.dialer.upSection.content.value}
                </p>
              </div>

              <div
                className="jumbotron text-white "
                style={{
                  ...ui.dialer.downSection.style,
                  marginBottom: "initial",
                }}
              >
                <form onSubmit={tabSubmit}>
                  <div className="form-group">
                    <label
                      title={ui.dialer.whatsAppLabel.tooltip}
                      style={ui.dialer.whatsAppLabel.style}
                    >
                      {ui.dialer.whatsAppLabel.text}
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Type a message and click on the send button"
                    ></textarea>
                  </div>

                  <div className="form-group text-right">
                    <button
                      className="btn btn-danger"
                      title={ui.dialer.whatsAppSendButton.tooltip}
                      style={ui.dialer.whatsAppSendButton.style}
                    >
                      {ui.dialer.whatsAppSendButton.text}{" "}
                      <img src="assets/icons/arrow-right.svg"></img>
                    </button>
                  </div>
                </form>
                <h5 style={{ marginTop: "3rem", marginBottom: "-3rem" }}>
                  {ui.dialer.downSection.helpText}
                </h5>
              </div>
            </div>

            <div
              className="card pb-0 tab-pane fade"
              style={{
                ...ui.dialer.upSection.style,
                zIndex: "9",
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
              }}
              id="emailTab"
            >
              <div className="card-body text-center">
                <h5 className="card-title">
                  {ui.dialer.upSection.content.label}
                </h5>
                <p className="card-text mb-3">
                  {ui.dialer.upSection.content.value}
                </p>
              </div>
              <div
                className="jumbotron text-white"
                style={{
                  ...ui.dialer.downSection.style,
                  marginBottom: "initial",
                }}
              >
                <form onSubmit={tabSubmit}>
                  <div className="form-group">
                    <label
                      title={ui.dialer.emailLabel.tooltip}
                      style={ui.dialer.emailLabel.style}
                    >
                      {ui.dialer.emailLabel.text}
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Type a message and click on the send button"
                    ></textarea>
                  </div>

                  <div className="form-group text-right">
                    <button
                      className="btn btn-danger"
                      title={ui.dialer.emailSendButton.tooltip}
                      style={ui.dialer.emailSendButton.style}
                    >
                      {ui.dialer.emailSendButton.text}{" "}
                      <img src="assets/icons/arrow-right.svg"></img>
                    </button>
                  </div>
                </form>
                <h5 style={{ marginTop: "3rem", marginBottom: "-3rem" }}>
                  {ui.dialer.downSection.helpText}
                </h5>
              </div>
            </div>
          </div>

          <ul
            className={
              !ui.dialer.emailApi &&
              !ui.dialer.whatsappApi &&
              window.screen.width > 700
                ? "nav nav-tabs d-none"
                : "nav nav-tabs"
            }
            id="popupUl"
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              padding: "0.5rem 0",
              background: "#8b8b8c",
              borderColor: "#8b8b88",
              ...ui.dialer.footerStyle,
            }}
          >
            {callPopup && window.screen.width < 700 ? (
              <>
                <li onClick={popup} style={{ cursor: "pointer" }}>
                  <a>
                    <img src="/assets/icons/x-circle.svg"></img>
                  </a>
                </li>
              </>
            ) : (
              <>
                {" "}
                <li onClick={popup} className="active">
                  <a
                    data-toggle="tab"
                    href="#call"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 5px 10px #d4d2d4",
                      padding: "9px",
                      borderRadius: "15%",
                      zIndex: 99999,
                      backgroundColor: "#fff",
                    }}
                  >
                    <img src={ui.dialer.footer_call_icon}></img>
                    {mobileView && <span>{ui.dialer.footer_call_label}</span>}
                  </a>
                </li>
                {ui.dialer.whatsappApi && (
                  <li onClick={popup}>
                    <a
                      data-toggle="tab"
                      href="#whatsappTab"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 5px 10px #d4d2d4",
                        padding: "9px",
                        borderRadius: "15%",
                        zIndex: 99999,
                        backgroundColor: "#fff",
                      }}
                    >
                      <img src={ui.dialer.footer_whatsapp_icon}></img>
                      {mobileView && (
                        <span>{ui.dialer.footer_whatsapp_label}</span>
                      )}
                    </a>
                  </li>
                )}
                {ui.dialer.emailApi && (
                  <li onClick={popup}>
                    <a
                      data-toggle="tab"
                      href="#emailTab"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 5px 10px #d4d2d4",
                        padding: "9px",
                        borderRadius: "15%",
                        zIndex: 99999,
                        backgroundColor: "#fff",
                      }}
                    >
                      <img src={ui.dialer.footer_email_icon}></img>
                      {mobileView && (
                        <span>{ui.dialer.footer_email_label}</span>
                      )}
                    </a>
                  </li>
                )}{" "}
              </>
            )}
          </ul>

          <div>
            {/* Button trigger modal */}
            {/* Modal for Call me now*/}
            <div
              className="modal fade"
              id="callmenow"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {ui.dialer.downSection.btn1.value}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body text-center">
                    <p>{btnMsg}</p>
                    {/* <p>{ui.dialer.downSection .btn1.popUp.content}</p> */}
                    <h5 className="m-4">
                      {" "}
                      {ui.mobile.slice(0, 2) + "XXXX" + ui.mobile.slice(6, 10)}
                    </h5>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      style={ui.dialer.downSection.btn1.popUp.closeBtn.style}
                      title={ui.dialer.downSection.btn1.popUp.closeBtn.tooltip}
                    >
                      {ui.dialer.downSection.btn1.popUp.closeBtn.value}
                    </button>
                  </div>
                  <div className="modal-footer">
                    <small>
                      {ui.dialer.downSection.btn1.popUp.bottomContent}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal for Call me Later */}

          <div
            className="modal fade"
            id="callmelater"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {ui.dialer.downSection.btn2.value}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <p className="text-center">
                    {ui.dialer.downSection.btn2.popUp.content}
                  </p>
                  {submit.message && (
                    <div className="alert alert-warning">{submit.message}</div>
                  )}
                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Date"
                          id="schedule-date"
                          style={date.err ? { borderColor: "red" } : null}
                          value={date.value}
                          onChange={(e) => {
                            let date = e.target.value;
                            const dateArr = date.split("-");
                            if (dateArr[1].length === 2) {
                              const month = months[parseInt(dateArr[1])];
                              date =
                                dateArr[2] + "-" + month + "-" + dateArr[0];
                            }
                            setFormatedDate(date);
                            setDate({ err: false, value: e.target.value });
                          }}
                        />
                        {date.err && (
                          <span style={{ color: "red" }}>
                            Date is required.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">Time</label>

                        <input
                          type="time"
                          className="form-control"
                          placeholder="Enter Time"
                          id="schedule-time"
                          style={time.err ? { borderColor: "red" } : null}
                          value={time.value}
                          title="Please enter time in HH:MM AM/PM"
                          onChange={(e) =>
                            setTime({ err: false, value: e.target.value })
                          }
                        />
                        {time.err && (
                          <span style={{ color: "red" }}>
                            Time is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="m-4">
                    {ui.mobile.slice(0, 2) + "XXXX" + ui.mobile.slice(6, 10)}
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={ui.dialer.downSection.btn2.popUp.scheduleBtn.style}
                    title={ui.dialer.downSection.btn2.popUp.scheduleBtn.tooltip}
                    onClick={scheduleSubmit}
                  >
                    {submit.loading ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}{" "}
                    {ui.dialer.downSection.btn2.popUp.scheduleBtn.value}
                  </button>
                </div>
                <div className="modal-footer">
                  <small>
                    {ui.dialer.downSection.btn2.popUp.bottomContent}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Call;

// complience.officer@mstock.com
